@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Auth_left__3-qeA{
    background-color: #F6FAFC;
    height: 100vh;
    overflow: auto;
  }
  
  .Auth_right__vFNtH {
    background-color: #ffffff;
    height: 100vh;
    align-self: flex-start;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.74) 90.67%), url(https://avidrealtypartners.com/wp-content/uploads/2018/09/4_12_APW_EXT1_1920_10801.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .Auth_ctr__3crSM {
    height: 100vh;
    margin-left: 0;
    margin-right:0;
  }
  
  .Auth_login__2nA9F{
    max-width: 90%;
  }


  .Auth_headerTitle__1Lo7Z {
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    font-weight: 700;
  }

  
  .Auth_featuredContent__1oBsd {
    position: absolute;  
    
    bottom: 50px;
    left: 50px;
    color: white;
    font-family: 'Poppins', sans-serif;

    font-size: 1.5em;
    font-style: normal;
    font-weight: 300;

    display: block;

  }

  
  .Auth_featuredTrialHeader__1kpbr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 3.5em;
    line-height: 1.1em;
    margin-bottom: 0.3em;
  }

  .Auth_featuredWebsiteLink__AAG8h {
    border: 1px solid white;
    padding: 0.4em 1.8em;
    display: table-cell;
    opacity: .5;
  }

  .Auth_featuredWebsiteLink__AAG8h:hover {
    opacity: 1.0;    
  }

  .Auth_featuredPerson__1WT-b {
    position: absolute;
    bottom: 0px;
    right: 40px;
  }


  @media only screen and (max-width: 700px) {
    .Auth_ctr__3crSM {
      margin-left: 0;
      margin-right:0;
      flex-direction: column-reverse;
    }
    .Auth_featuredPerson__1WT-b {
      display: none;
    }

    .Auth_featuredWebsiteLink__AAG8h {
      display: none;
    }

    .Auth_featuredContent__1oBsd {
      font-size: 0.8em;
    }
    .Auth_featuredTrialHeader__1kpbr {

      font-size: 2em;
      line-height: 1em;
      margin-bottom: 0.1em;
    }
    .Auth_left__3-qeA{
      overflow: visible;
      height: auto;
    }
  }
